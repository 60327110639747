@import '../style/varibale';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: $colorLight;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.modal {
  z-index: 999999997 !important;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $mainColor;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color: $mainColor, $alpha: 0.8);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

button {
  // border-color: $mainColor !important;
}

td {
  vertical-align: middle !important;
}

.ClientPhone {
  direction: ltr !important;
}



.p-column-title {
  width: 100% !important;
  text-align: center !important;
}

.p-galleria .p-galleria-item-nav {
  background-color: rgba($color: #000000, $alpha: 0.3) !important;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.2) !important;
  }
}

.bg_delete {
  background-color: #DC2626 !important;
  cursor: pointer;
  bottom: 5px !important;
}


[dir="rtl"] .btn_gapAr {
  .p-button {
    gap: 9px;
  }
}

// [dir="rtl"] .p-button {
//   display: flex;
//   gap: 10px;
// }


[dir="rtl"] .p-dropdown-item {
  display: flex !important;
  justify-content: start !important;
}


.p-dropdown-item {
  display: flex !important;
  justify-content: end !important;
}