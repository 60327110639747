@import './varibale';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,500&family=Roboto+Mono:wght@200;300;400;500;600;700&family=Roboto:wght@700;900&family=Tajawal:wght@300;400;500;700;800;900&display=swap');

.color-red {
  color: $mainColor !important;
}

.secondColor {
  color: $secondColor !important;
}

a {
  text-decoration: none !important;
} 
// pagr not found
.app__error {
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.notfound-image {
  @media screen and (max-width:767px) {
    width: 410px;

  }
}

.notfound-image {
  width: 100px !important;
} 
.image_view {
  // width: 150px !important;
  max-height:250px !important;
  border-radius: 10px !important;
}

.p-datatable-striped {
  td{
    text-align: center !important;

  }
}

.p-inputtext.p-inputtext-sm {
  font-size: 15px !important; 
}
.colors_tabel{
  display: flex ;
  width: 15px !important;
  height: 15px !important; 
  border-radius: 50%;
}

.galleria-container{ 
  .p-galleria-mask{
    z-index: 999999999999999999 !important; 
    background-color: rgba($color: #000000, $alpha: 50%);
  }
  .p-galleria-mask .p-galleria-item-nav {
    position: fixed;
    top: 50%;
    margin-top: -0.5rem;
    z-index: 999999999999999999999 !important;
   
  }
}


.upload_image{ 
  img{
    width: 200px;
    cursor: pointer;
    @media screen and (width < 767px) {
      width: 150px;
    }
  }
  label{ 
    width: 100%;
    text-align: center;
    margin-right: 30px;
  }
}

.thumbnail_images{ 
  img{
    width: 100px ;
    height: 100px;
    object-fit: contain;
  }
}